import createImageUrlBuilder from '@sanity/image-url'
import type { Image } from 'sanity'

import { dataset, projectId } from '@/sanity/lib/env'

const imageBuilder = createImageUrlBuilder({
	projectId: projectId || '',
	dataset: dataset || '',
})

export const urlForImage = (source: Image | undefined) => {
	// Ensure that source image contains a valid reference
	if (!source?.asset) return undefined
	// @ts-ignore
	const imageUrl = source.asset?.url
	// Test if image url is GIF
	const isGif = imageUrl?.endsWith('.gif')
	if (isGif) return imageBuilder?.image(source)
	return imageBuilder?.image(source).auto('format').fit('max')
}

export function urlForOpenGraphImage(image: Image | undefined) {
	return urlForImage(image)?.width(1200).height(627).fit('crop').url()
}
