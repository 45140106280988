import { PortableText } from "@portabletext/react"
import Image from 'next/image'
import { urlForImage } from '@/sanity/lib/image'
import Link from 'next/link'
import { PortableTextBlock } from '@portabletext/types'

interface RichTextProps {
	content: PortableTextBlock[]
}

export const RichTextComponents = {
	types: {
		image: ({value}:any) => (
			<div className="mb-18 sm:mb-0 sm:sticky sm:top-[calc(var(--header-height)_+_8rem)] sm:float-left sm:-ml-[calc(100%+2rem)] w-full border-solid border-success border">
				<div className="w-full md:px-[17.0391061452514%] sm:min-h-[calc(100vh-var(--header-height)-16rem)] flex items-center justify-center bg-white">
					<Image
						className={`w-full h-auto`}
						src={urlForImage(value).width(1440)?.url()}
						width={value?.asset?.metadata?.dimensions?.width}
						height={value?.asset?.metadata?.dimensions?.height}
						sizes="100vw"
						alt={value?.alt || ''}
						placeholder="blur"
						blurDataURL={value?.asset?.metadata?.lqip}
						unoptimized={value?.asset?.url?.includes(".gif") ? true : false} // mark Gif images as unoptimized (Next.js does not support Gif optimisation yet)
					/>
				</div>
			</div>
		),
		// hr: () => <div><div className="hr block appearance-none h-0 border-solid border-blue-light border-b border-t-0 my-12 md:my-16" /></div>
	},
	block: {
		// h2: ({ children } : any) => <h2 className="font-headline fluid-text-13 leading-[1.7] tracking-[0.0325em] uppercase">{children}</h2>,
		// h3: ({ children } : any) => <h3 className="font-headline fluid-text-13 leading-[1.7] tracking-[0.0325em] uppercase">{children}</h3>,
		// h4: ({ children } : any) => <h4 className="">{children}</h4>,
		normal: ({ children } : any) => <p>{children}</p>,
		// statement: ({ children } : any) => <p className="font-500 text-30 leading-120">{children}</p>,
		blockquote: ({ children } : any) => <blockquote className="font-display italic leading-120 my-16">{children}</blockquote>,
	},
	marks: {
		link: ({ children, value } : any) => {
			const target = !value?.url?.startsWith('/') ? '_blank' : '_self'
			const rel = !value?.url?.startsWith('/') ? 'noreferrer noopener' : ''
			return target === '_blank' ? (
				<a href={value?.url} className="text-current hover:text-blue underline" rel={rel} target={target}>
					{children}
				</a>
			) : (
				<Link
					href={value?.url}
					className="text-current hover:text-blue underline"
					rel={rel}
					target={target}>
					{children}
				</Link>
			)
		}
	}
}

export default function RichText({content}:RichTextProps) {
	return (
		<PortableText
			value={content}
			components={RichTextComponents}
		/>
	)
}