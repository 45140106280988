import { create } from 'zustand'

const useApp = create<{
	isLoading: boolean
	setIsLoading: (status: boolean) => void

	isNavOpen: boolean
	setIsNavOpen: (status: boolean) => void

	isCartOpen: boolean
	setIsCartOpen: (status: boolean) => void

	isDarkMode: boolean
	setIsDarkMode: (status: boolean) => void

	cookieOptions: string
	setCookieOptions: (status: string) => void

	isTrackingEnabled: boolean
	setIsTrackingEnabled: (status: boolean) => void

	isCookieManagerOpen: boolean
	setIsCookieManagerOpen: (status: boolean) => void

	isAnnouncementNoticeOpen: boolean
	setIsAnnouncementNoticeOpen: (status: boolean) => void

	cart: any
	setCart: (status: any) => void

	introScrollProgress: number
	setIntroScrollProgress: (progress: number) => void
}>((set) => ({
	isLoading: true,
	setIsLoading: (status) => {
		set((state) => ({
			...state,
			isLoading: status
		}))
	},

	isNavOpen: false,
	setIsNavOpen: (status) => {
		set((state) => ({
			...state,
			isNavOpen: status
		}))
	},

	isCartOpen: false,
	setIsCartOpen: (status) => {
		set((state) => ({
			...state,
			isCartOpen: status
		}))
	},

	isDarkMode: false,
	setIsDarkMode: (status) => {
		set((state) => ({
			...state,
			isDarkMode: status
		}))
	},

	cookieOptions: 'none',
	setCookieOptions: (status) => {
		set((state) => ({
			...state,
			cookieOptions: status
		}))
	},

	isTrackingEnabled: false,
	setIsTrackingEnabled: (status) => {
		set((state) => ({
			...state,
			isTrackingEnabled: status
		}))
	},

	isCookieManagerOpen: false,
	setIsCookieManagerOpen: (status) => {
		set((state) => ({
			...state,
			isCookieManagerOpen: status
		}))
	},

	isAnnouncementNoticeOpen: true,
	setIsAnnouncementNoticeOpen: (status) => {
		set((state) => ({
			...state,
			isAnnouncementNoticeOpen: status
		}))
	},

	cart: null,
	setCart: (status) => {
		set((state) => ({
			...state,
			cart: status
		}))
	},

	introScrollProgress: 0,
	setIntroScrollProgress: (progress: number) => {
		return set(() => ({
			introScrollProgress: progress
		}))
	}
}))

export default useApp
